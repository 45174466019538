import React from "react"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
// import Layout from "../components/layout"
import SEO from "../helpers/seo"
import Img from "gatsby-image"

const CaseStudyLongForm = ({ data }) => {
  const {
    title,
    clientName,
    ask,
    scope,
    solution,
    squareImage1,
    squareImage2,
    fact1,
    fact2,
    fact3,
    heroImage1,
    heroImage2,
    heroImage3,
    heroImage4,
    heroImage5,
    rectangleImage1,
    rectangleImage2,
    rectangleImage3,
    text1,
    text2,
    text3,
    tagline,
  } = data.contentfulCaseStudyLongForm

  const createMarkup = content => {
    return { __html: content }
  }

  return (
    <React.Fragment>
      <Helmet>
        <body className="case-study-long-form-page" />
      </Helmet>
      <SEO title={title} />
      <div className="content-wrapper">
        <div className="intro">
          <div className="content">
            <div className="title">CLIENT</div>
            <p>{clientName}</p>
          </div>
          <div className="content">
            <div className="title">ASK</div>
            <p>{ask.ask}</p>
          </div>
          <section
            className="single-image mobile-show"
            style={{ marginBottom: 40 }}
          >
            {imageVideoComponent(heroImage1)}
          </section>
          <div className="content">
            <div className="title">SCOPE</div>
            <p>{scope.scope}</p>
          </div>
          <div className="content">
            <div className="title">SOLUTION</div>
            <p>{solution.solution}</p>
          </div>
        </div>
        <div className="case-study-content">
          <section className="single-image image1 desktop-show">
            {imageVideoComponent(heroImage1)}
          </section>

          <section className="image-with-text">
            <div className="text">{text1.text1}</div>
            <div className="image image2">
              {imageVideoComponent(rectangleImage1)}
            </div>
          </section>

          <section className="single-image image3">
            {imageVideoComponent(heroImage2)}
          </section>

          <section className="tagline">
            <div
              dangerouslySetInnerHTML={createMarkup(
                tagline.content[0].content[0].value
              )}
            />
          </section>

          <section className="image-blocks">
            {imageVideoComponent(squareImage1)}
            {imageVideoComponent(squareImage2)}
          </section>

          <section className="single-image image6">
            {imageVideoComponent(heroImage3)}
          </section>

          <section className="image-with-text text-right">
            <div className="text">{text2.text2}</div>
            <div className="image image2">
              {imageVideoComponent(rectangleImage2)}
            </div>
          </section>

          <section className="single-image image8">
            {imageVideoComponent(heroImage4)}
          </section>

          <section className="image-with-text text-right">
            <div className="text">{text3.text3}</div>
            <div className="image image2">
              {imageVideoComponent(rectangleImage3)}
            </div>
          </section>

          <section className="facts">
            <div
              className="fact"
              dangerouslySetInnerHTML={createMarkup(
                fact1.content[0].content[0].value
              )}
            />
            <div
              className="fact"
              dangerouslySetInnerHTML={createMarkup(
                fact2.content[0].content[0].value
              )}
            />
            <div
              className="fact"
              dangerouslySetInnerHTML={createMarkup(
                fact3.content[0].content[0].value
              )}
            />
          </section>

          <section className="single-image image10">
            {imageVideoComponent(heroImage5)}
          </section>
        </div>
      </div>
    </React.Fragment>
  )
}

export function imageVideoComponent(elem) {
  if (elem.file.contentType.includes("video")) {
    return (
      <div className="gatsby-image-wrapper">
        <video
          width="100%"
          height="auto"
          autoPlay
          muted
          loop
          playsInline
          style={{ objectFit: "cover" }}
        >
          <source src={elem.file.url} type={elem.file.contentType} />
        </video>
      </div>
    )
  } else {
    return <Img fluid={elem.fluid} alt={elem.title} />
  }
}

export default CaseStudyLongForm

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulCaseStudyLongForm(slug: { eq: $slug }) {
      clientName
      title
      fact1 {
        content {
          content {
            value
          }
        }
      }
      fact2 {
        content {
          content {
            value
          }
        }
      }
      fact3 {
        content {
          content {
            value
          }
        }
      }
      tagline {
        content {
          content {
            value
          }
        }
      }
      squareImage1 {
        title
        file {
          contentType
          url
        }
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      squareImage2 {
        title
        file {
          contentType
          url
        }
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      heroImage1 {
        title
        file {
          contentType
          url
        }
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      heroImage2 {
        title
        file {
          contentType
          url
        }
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      heroImage3 {
        title
        file {
          contentType
          url
        }
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      heroImage4 {
        title
        file {
          contentType
          url
        }
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      heroImage5 {
        title
        file {
          contentType
          url
        }
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      rectangleImage1 {
        title
        file {
          contentType
          url
        }
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      rectangleImage2 {
        title
        file {
          contentType
          url
        }
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      rectangleImage3 {
        title
        file {
          contentType
          url
        }
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      text1 {
        text1
      }
      text2 {
        text2
      }
      text3 {
        text3
      }
      solution {
        solution
      }
      ask {
        ask
      }
      scope {
        scope
      }
    }
  }
`
